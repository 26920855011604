<template>
    <div class="flights-results-cont">
        <v-snackbar v-model="snackbar" top multi-line :timeout="5000" :color="color">
          <v-row align="center" justify="space-between">
            {{text}}
            <v-spacer></v-spacer>
            <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
          </v-row>
        </v-snackbar>
        <v-container fluid>
          <v-row>
            <v-col v-if="flightResults && !loaded" cols="12" class="d-block d-md-none mt-2">
              <v-expansion-panels hover class="mb-5" v-if="$vuetify.breakpoint.smAndDown">
                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-chevron-down"><span><v-icon>mdi-magnify</v-icon> Change Search</span></v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <packageControls />

                    <v-btn block color="headers white--text"
                    class="my-5 py-7 font-weight-bold text-h6"
                    elevation="0"
                    :disabled="!originAirport || !homeAirport || !startDate || !returnDate"
                    @click="searchFlights"
                    >
                    change flights
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-expansion-panels hover v-if="flights.length">
                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="mdi-chevron-down"><span><v-icon>mdi-filter-outline</v-icon> Filter Results</span></v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <filters v-if="showMatrix" @changed="filter"/>
                    <p v-else class="text-center">Preparing Filters ...</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" md="4">
              <!-- flights Search -->
              <div v-if="$vuetify.breakpoint.mdAndUp">
                <v-card class="pa-5 mb-5">
                  <packageControls />
                  <div class="btn-sec">
                    <v-btn block color="headers white--text"
                    class="my-5 py-7 font-weight-bold text-h6"
                    elevation="0"
                    :disabled="!originAirport || !homeAirport || !startDate || !returnDate"
                    @click="searchFlights"
                    :loading="searchLoad"
                    >
                    {{$t('flightSearch.changeFlight')}}
                    </v-btn>
                  </div>
                </v-card>
              </div>

              <v-card v-if="!loaded" width="100%" class="d-none d-md-block">
                <v-card-title class="primary white--text headline">
                  {{$t('flightSearch.filters.filterFlights')}}
                </v-card-title>
                <filters v-if="showMatrix" class="px-2" @changed="filter" />
                <p v-else class="text-center">Preparing Filters ...</p>
              </v-card>
            </v-col>
            <v-col cols="12" md="8" v-if="!flightResults && loaded">
              <v-row justify="center" align="start" style="min-height: 80vh" class="mt-15">
                <div>
                  <v-img max-width="200" class="blink-2" src="../../assets/logo-adam.png"></v-img>
                </div>
              </v-row>
            </v-col>
            <v-col v-if="(!flights.length || !flightResults) && !loaded" cols="12" sm="8">
              <p class="body-1 font-weight-bold mx-auto primary--text text-center">Sorry, No flights found .</p>
            </v-col>
            <v-col v-if="flightResults && !loaded" cols="12" md="8">
              <flightCard v-if="flights.length" @error="applyError" :flight="flights" />
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import flightCard from '@/components/flights/flightCard.vue'
import filters from '@/components/flights/filters.vue'
import flightsAPI from '@/requests/flights.js'
// import flightsSearch from '@/components/flights/flightsSearch'
import packageControls from '@/components/packageControls'
import { mapState } from 'vuex'

export default {
  title: '-Flight results',
  components: {
    flightCard,
    filters,
    // flightsSearch,
    packageControls
  },
  data () {
    return {
      flights: [],
      snackbar: false,
      color: '',
      text: '',
      showMatrix: false,
      loaded: true,
      searchLoad: false
    }
  },
  computed: {
    // flightsStore () {
    //   if (this.$store.state.flightResults) return this.$store.state.flightResults.flights
    //   return null
    // },
    ...mapState(['originAirport', 'returnAirport', 'destinationAirport', 'homeAirport',
      'flightClass', 'startDate', 'returnDate', 'adults', 'children',
      'oldChildren', 'infants', 'flightResults', 'roomsAndoccupancy'
    ])
  },
  watch: {
    flightResults (newVal) {
      if (newVal) this.flights = newVal.flights
      else this.flights = []
    }
  },
  methods: {
    filter (flights) {
      this.flights = flights
    },
    applyError (text) {
      this.snackbar = true
      this.color = 'error'
      this.text = text
    },
    displayError (msg) {
      this.alertExist = true
      this.alertType = 'error'
      this.alertText = msg
    },
    searchFlights () {
      this.searchLoad = true
      const params = new URLSearchParams()
      let isMultiCityFlight = true
      if (this.originAirport.Code === this.homeAirport.Code && this.destinationAirport.Code === this.returnAirport.Code) isMultiCityFlight = false
      if (!isMultiCityFlight) {
        params.append('tripType', 'round')
        params.append('origin', this.originAirport.Code)
        params.append('destination', this.destinationAirport.Code)
        params.append('originType', this.originAirport.Type)
        params.append('destinationType', this.destinationAirport.Type)
        params.append('departureDate', this.startDate)
        params.append('arrivalDate', this.returnDate)
      } else {
        // depart flight
        params.append('tripType', 'multi')
        params.append('origin[]', this.originAirport.Code)
        params.append('originType[]', this.originAirport.Type)
        params.append('destination[]', this.destinationAirport.Code)
        params.append('destinationType[]', this.destinationAirport.Type)
        params.append('departureDate[]', this.startDate)
        // return flight
        params.append('origin[]', this.returnAirport.Code)
        params.append('originType[]', this.returnAirport.Type)
        params.append('destination[]', this.homeAirport.Code)
        params.append('destinationType[]', this.homeAirport.Type)
        params.append('departureDate[]', this.returnDate)
      }
      params.append('classType', this.flightClass.value)
      let adults = 0
      let childrenUnderSix = 0
      let childrenAboveSix = 0
      let infants = 0
      this.roomsAndoccupancy.forEach(room => {
        adults += room.adults
        childrenUnderSix += room.children.length ? room.children.filter(child => child.age >= 2 && child.age < 6).length : 0
        childrenAboveSix += room.children.length ? room.children.filter(child => child.age >= 6).length : 0
        infants += room.children.length ? room.children.filter(child => child.age < 2).length : 0
      })
      params.append('adults', adults)
      params.append('children_under_six', childrenUnderSix)
      params.append('children_above_six', childrenAboveSix)
      params.append('infants', infants)
      this.$route.query.flightQuery = params.toString()
      this.getFlights(params.toString())
    },
    getFlights (params) {
      this.showMatrix = false
      this.$store.dispatch('removeFlightResults')
      this.loaded = true
      flightsAPI.getAllFlights(params).then(res => {
        if (res.data.status) {
          res.data.data.flights.sort((a, b) => {
            return a.pricingInfo.TotalFare - b.pricingInfo.TotalFare
          })
          this.flights = res.data.data.flights
          this.$store.dispatch('setFlightResults', res.data.data)
        } else {
          this.applyError('Could not find flights!')
        }
      })
        .finally(() => {
          window.scrollTo(0, 0)
          this.loaded = false
          this.showMatrix = true
          this.searchLoad = false
        })
    }
  },
  created () {
    if (this.originAirport) {
      window.scrollTo(0, 0)
      this.searchFlights()
    } else {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style>
    .background .container {
      padding-top: 25px;
    }
    .flights-results-cont {
      background-color: rgb(246, 246, 246);
    }
    .blink-2 {
      -webkit-animation: blink-2 1.5s infinite both;
              animation: blink-2 1.5s infinite both;
    }
    .btn-sec {
      position: sticky;
      background-color: white;
      bottom: 0px;
      padding: 3px;
    }
    @-webkit-keyframes blink-2 {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.2;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes blink-2 {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.2;
      }
      100% {
        opacity: 1;
      }
    }
</style>
