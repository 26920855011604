<template>
    <div id="app-flight-filter">
        <p class="grey--text ml-1 mt-1">{{flightsCount}} {{$t('flightSearch.filters.flights')}}</p>
        <v-divider></v-divider>

        <h5 v-if="maxStopsDuration.value - minStopsDuration.value > 0" color="primary" class="headline ml-2">{{$t('flightSearch.filters.stopDuration')}}</h5>
        <h5 v-if="maxStopsDuration.value - minStopsDuration.value > 0" color="primary" class="caption mt-2 ml-2 mb-5"><strong>{{$t('flightSearch.filters.stopDurationRange')}}: <span class="primary--text">{{convertDecimalToText(stopsDurationRange[0])}}</span> - <span class="secondary--text">{{convertDecimalToText(stopsDurationRange[1])}}</span></strong></h5>
        <v-range-slider
        v-model="stopsDurationRange"
        :min="minStopsDuration.value"
        :max="maxStopsDuration.value"
        step="0.25"
        color="primary"
        hide-details
        class="align-center mx-2"
        @change="filterate()"
        v-if="maxStopsDuration.value - minStopsDuration.value > 0" >
        </v-range-slider>
        <v-divider></v-divider>
        <!-- <v-checkbox v-if="alternateFlightsExist" v-model="alternateFares" color="primary" @change="filterate()">
          <template v-slot:label>
            <span class="body-1 primary--text">Alternate Dates' Fares Only</span>
          </template>
        </v-checkbox>
        <v-checkbox v-if="mailonlyFaresExist && !$cookies.isKey('userToken')" v-model="mailOnlyFares" color="primary" @change="filterate()">
          <template v-slot:label>
            <span class="body-1 primary--text">Email Only Fare</span>
          </template>
        </v-checkbox>
        <v-checkbox v-if="alternateFlightsExist || mailonlyFaresExist" v-model="exactFares" color="primary" @change="filterate()">
          <template v-slot:label>
            <span class="body-1 primary--text">Requested Date Flights</span>
          </template>
        </v-checkbox>
        <v-divider></v-divider> -->
        <v-container fluid>
            <h5 class="mb-0 headline">{{$t('flightSearch.filters.stops')}}</h5>
            <v-row class="my-0 py-0" no-gutters>
                <v-col cols="4" md="12" v-for="(item, i) in stops" :key="i" class="ma-0 pa-0">
                    <v-checkbox v-model="selectedStops" :value="item" color="primary" @change="filterate()">
                        <template v-slot:label>
                          <p class="body-1 font-weight-medium mb-0">{{$tc('flightSearch.filters.stop', item)}}</p>
                          <!-- {{item === 0 ? 'Non Stop' : (item === 1 ? 'One Stop' : 'Multi Stop')}} -->
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container fluid>
            <h5 class="mb-0 headline">{{$t('flightSearch.filters.airlines')}}</h5>
            <v-row class="my-0 py-0" no-gutters>
                <v-col cols="4" md="12" v-for="(item, i) in airlines" :key="i" class="ma-0 pa-0">
                    <v-checkbox v-model="airs" :disabled="airlines.length < 2" :value="item.airlineCode" color="primary" @change="filterate()">
                        <template v-slot:label>
                          <v-tooltip color="primary" bottom>
                            <template v-slot:activator="{ on }">
                              <!-- {{item.airlineName.substr(0,5)}}- -->
                              <p v-on="on" class="body-1 font-weight-medium mb-0">{{item.airlineName}}</p>
                            </template>
                            <span>{{item.airlineName}}</span>
                          </v-tooltip>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container fluid v-if="stopAirports.length > 0">
            <h5 class="mb-0 headline">{{$t('flightSearch.filters.layoverLocation')}}</h5>
            <v-row class="my-0 py-0" no-gutters>
                <v-col cols="4" md="12" v-for="(item, i) in stopAirports" :key="i" class="ma-0 pa-0">
                    <v-checkbox v-model="selectedStopAirports" :value="item" color="primary" @change="filterate()">
                        <template v-slot:label>
                          <v-tooltip color="primary" bottom>
                            <template v-slot:activator="{ on }">
                              <p v-on="on" class="body-1 font-weight-medium mb-0">{{item}}</p>
                            </template>
                            <span>{{item}}</span>
                          </v-tooltip>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container fluid>
            <h5 class="mb-0 headline">{{$t('flightSearch.filters.aircraftModel')}}</h5>
            <v-row class="my-0 py-0" no-gutters>
                <v-col cols="4" md="12" v-for="(item, i) in aircrafts" :key="i" class="ma-0 pa-0">
                    <v-checkbox v-model="selectedAircrafts" :disabled="aircrafts.length < 2" :value="item" color="primary" @change="filterate()">
                        <template v-slot:label>
                          <v-tooltip color="primary" bottom>
                            <template v-slot:activator="{ on }">
                              <!-- {{item.airlineName.substr(0,5)}}- -->
                              <p v-on="on" class="body-1 font-weight-medium mb-0">{{item}}</p>
                            </template>
                            <span>{{item}}</span>
                          </v-tooltip>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
  props: ['alternateFlightsExist', 'mailonlyFaresExist'],
  data () {
    return {
      prices: [],
      showArrival1: false,
      showArrival2: false,
      departTimes1: [0, 1425],
      arrivalTimes1: [0, 1425],
      departTimes2: [0, 1425],
      arrivalTimes2: [0, 1425],
      min: 0,
      max: 0,
      minDepartTime1: '12:00 AM',
      maxDepartTime1: '11:45 PM',
      minArrivalTime1: '12:00 AM',
      maxArrivalTime1: '11:45 PM',
      minDepartTime2: '12:00 AM',
      maxDepartTime2: '11:45 PM',
      minArrivalTime2: '12:00 AM',
      maxArrivalTime2: '11:45 PM',
      minStopsDuration: '',
      maxStopsDuration: '',
      stopsDurationRange: [],
      airs: [],
      airlines: [],
      alternateFares: false,
      mailOnlyFares: false,
      exactFares: true,
      flights: [],
      aircrafts: [],
      selectedAircrafts: [],
      stops: [],
      selectedStops: [],
      stopAirports: [],
      selectedStopAirports: [],
      flightsCount: 0
    }
  },
  computed: {
    flightResults () {
      return this.$store.state.flightResults
    }
  },
  watch: {
    flightResults (newVal, oldVal) {
      this.min = Math.floor(newVal.minPrice)
      this.max = Math.floor(newVal.maxPrice)
      this.prices = [this.min, this.max]
      this.airlines = newVal.airlines
      this.flights = newVal.flights
      this.airs = []
    }
  },
  methods: {
    convertDecimalToText (num) {
      let hours = 0
      if (Number(String(num).split('.')[0]) > 0) hours = Math.ceil(num)
      let minutes = 0
      if ((Number(String(num).split('.')[1]) > 0)) minutes = (Number(String(num).split('.')[1]) * 60) / 100
      return hours + ' h ' + Math.ceil(minutes) + ' m '
    },
    timesChanged (type, times) {
      switch (type) {
        case 'depart1':
          this.minDepartTime1 = this.formatTimes(times[0])
          this.maxDepartTime1 = this.formatTimes(times[1])
          break
        case 'arrival1':
          this.minArrivalTime1 = this.formatTimes(times[0])
          this.maxArrivalTime1 = this.formatTimes(times[1])
          break
        case 'depart2':
          this.minDepartTime2 = this.formatTimes(times[0])
          this.maxDepartTime2 = this.formatTimes(times[1])
          break
        case 'arrival2':
          this.minArrivalTime2 = this.formatTimes(times[0])
          this.maxArrivalTime2 = this.formatTimes(times[1])
          break
      }
      this.filterate()
    },
    formatTimes (time) {
      let hours = Math.floor(Number(time) / 60)
      let minutes = time - (hours * 60)
      if (hours.length === 1) hours = '0' + hours
      if (minutes.length === 1) minutes = '0' + minutes
      if (minutes === 0) minutes = '00'
      if (hours >= 12) {
        if (hours === 12) {
          minutes = minutes + ' PM'
        } else {
          hours = hours - 12
          minutes = minutes + ' PM'
        }
      } else {
        minutes = minutes + ' AM'
      }
      if (hours === 0) {
        hours = 12
      }
      return hours + ':' + minutes
    },
    convertTime (time) {
      const suffix = time.split(' ')[1]
      let hour = time.split(' ')[0].split(':')[0]
      const minutes = time.split(' ')[0].split(':')[1]
      if (suffix === 'PM') {
        if (hour !== '12') {
          hour = hour * 1 + 12
        }
      } else if (suffix === 'AM' && hour === '12') {
        hour = '00'
      }
      return hour + ':' + minutes + ':00'
    },
    filterate () {
      let filtered = this.flights.filter((flight, i) => {
        if (
          this.matchStops(flight) &&
          this.matchAirlines(flight) &&
          this.matchAircrafts(flight) &&
          this.matchLayover(flight) &&
          this.matchTimes(flight) &&
          this.matchStopsDurations(flight)
        ) return flight
      })
      filtered = filtered.sort((a, b) => parseFloat(a.pricingInfo.TotalFare) - parseFloat(b.pricingInfo.TotalFare))
      this.flightsCount = filtered.length
      this.$emit('changed', filtered)
    },
    matchStops (flight) {
      if (this.selectedStops.length) {
        let isValid = false
        flight.flightSegments.forEach(segment => {
          if (this.selectedStops.includes(segment.stops)) isValid = true
        })
        return isValid
      } else return true
    },
    matchAirlines (flight) {
      if (this.airs.length) {
        let isValid = false
        flight.flightSegments.forEach(trip => {
          trip.Segments.forEach(segment => {
            if (this.airs.includes(segment.MarketingAirlineCode)) isValid = true
          })
        })
        return isValid
      } else return true
    },
    matchAircrafts (flight) {
      if (this.selectedAircrafts.length) {
        let isValid = false
        flight.flightSegments.forEach(trip => {
          trip.Segments.forEach(segment => {
            if (this.selectedAircrafts.includes(segment.aircraft)) isValid = true
          })
        })
        return isValid
      } else return true
    },
    matchTimes (flight) {
      if (this.$store.state.flightType === 'one') {
        let isValid = false
        const segments = flight.flightSegments[0].Segments
        if (this.convertTime(this.minDepartTime1) < segments[0].DepartureTime + ':00' && segments[0].DepartureTime + ':00' < this.convertTime(this.maxDepartTime1) && this.convertTime(this.minArrivalTime1) < segments[segments.length - 1].ArrivalTime + ':00' && segments[segments.length - 1].ArrivalTime + ':00' < this.convertTime(this.maxArrivalTime1)) {
          isValid = true
        }
        return isValid
      } else if (this.$store.state.flightType === 'round') {
        let isValid = false
        const flightSegments = flight.flightSegments
        if (this.convertTime(this.minDepartTime1) < flightSegments[0].Segments[0].DepartureTime + ':00' && flightSegments[0].Segments[0].DepartureTime + ':00' < this.convertTime(this.maxDepartTime1) && this.convertTime(this.minArrivalTime1) < flightSegments[0].Segments[flightSegments[0].Segments.length - 1].ArrivalTime + ':00' && flightSegments[0].Segments[flightSegments[0].Segments.length - 1].ArrivalTime + ':00' < this.convertTime(this.maxArrivalTime1)) {
          if (this.convertTime(this.minDepartTime2) < flightSegments[1].Segments[0].DepartureTime + ':00' && flightSegments[1].Segments[0].DepartureTime + ':00' < this.convertTime(this.maxDepartTime2) && this.convertTime(this.minArrivalTime2) < flightSegments[1].Segments[flightSegments[1].Segments.length - 1].ArrivalTime + ':00' && flightSegments[1].Segments[flightSegments[1].Segments.length - 1].ArrivalTime + ':00' < this.convertTime(this.maxArrivalTime2)) {
            isValid = true
          }
        }
        return isValid
      } else return true
    },
    matchLayover (flight) {
      if (this.selectedStopAirports.length) {
        let isValid = false
        flight.flightSegments.forEach(trip => {
          trip.Segments.forEach((segment, i) => {
            if (this.selectedStopAirports.includes(segment.DestinationLocation)) isValid = true
          })
        })
        return isValid
      } else return true
    },
    matchStopsDurations (flight) {
      const validDurations = []
      flight.flightSegments.forEach((trip, i) => {
        let stopsInRange = true
        trip.stopsDuration.forEach((duration, j) => {
          if (!(Number(this.stopsDurationRange[0]) < Number(duration.value) && Number(duration.value) < Number(this.stopsDurationRange[1]))) stopsInRange = false
        })
        validDurations.push(stopsInRange)
      })
      return validDurations.includes(true)
    },
    getUniqueList (type, data) {
      switch (type) {
        case 'airline':
          this.airlines = data.reduce((acc, current) => {
            const x = acc.find(item => item.airlineCode === current.airlineCode)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          break
        case 'aircraft':
          this.aircrafts = data.reduce((acc, current) => {
            const x = acc.find(item => item === current)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          break
        case 'stops':
          this.stops = data.reduce((acc, current) => {
            const x = acc.find(item => item === current)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          this.stops.map(item => this.selectedStops.push(item))
          break
        case 'stopAirports':
          this.stopAirports = data.reduce((acc, current) => {
            const x = acc.find(item => item === current)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, [])
          break
      }
    }
  },
  created () {
    this.min = Math.floor(this.$store.state.flightResults.minPrice)
    this.max = Math.floor(this.$store.state.flightResults.maxPrice)
    this.minStopsDuration = this.$store.state.flightResults.minStopsDuration
    this.maxStopsDuration = this.$store.state.flightResults.maxStopsDuration
    this.stopsDurationRange = [this.minStopsDuration.value, this.maxStopsDuration.value]
    this.prices = [this.min, this.max]
    this.getUniqueList('airline', this.$store.state.flightResults.airlines)
    this.getUniqueList('aircraft', this.$store.state.flightResults.aircraftModel)
    this.getUniqueList('stops', this.$store.state.flightResults.stops)
    this.getUniqueList('stopAirports', this.$store.state.flightResults.stopOverAirports)
    this.flights = this.$store.state.flightResults.flights
    this.flights = this.flights.filter(flight => !flight.mailOnlyFare)
    this.flightsCount = this.flights.length
  }
}
</script>

<style>
.v-slider--horizontal .v-slider__track-container {
  height: 4px !important;
}
</style>
